/* .select2-container , .form-group label{
        margin: 20px  !important;
}
.select2-container--open .select2-dropdown--below {
    margin:-20px -10px !important;
} */

.form-group label {
    margin-top: 10px;
    display: block !important;
    width : 100%;
}


.form-group>.select2 {
    display: block;
    width: 100% !important;
}

.form-row label {
    display: block !important;
    width : 100%;
}
