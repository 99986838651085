
.how {
    min-height: 400px;
    display: block;
    background-color : #f5f5f5;
    text-align: center;
  }
  .how h3 {
    line-height: 46px;
    padding-top: 40px;
    text-align: left;
  }
  
  
 
  .how-title {
    font-weight: 500;
  }
  
  .how .row {
    margin-top: 20px;
  }
  
  .how img {
    margin-bottom: 40px;
  }
  .how p {
    font-weight: 300;
    height: 80px;
    padding: 20px 0;
  }
  