.result {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    /* width: 47%; */
    height: 295px;
    /* float: left; */
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
    margin: 10px;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px;
}

.result .image {
    overflow: hidden;
    background-size: contain;
    background-position: center;
    height: 50%;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
}



.result-body {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    text-align: left;
}

/* .result-body button {
    font-size: 0.9em;
    line-height: 1em
} */

.result-title {
    color: #EF4657;
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 1rem;
    padding: 0 1.25rem;
    line-height: 1.2rem;
}


.result-footer {
    padding: .75rem 1.25rem;
    background-color: rgba(0,0,0,.03);
    border-top: 1px solid rgba(0,0,0,.125);
}

.result-footer:last-child {
    border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px);
}


.result-text {
    font-size: 12px;
    line-height: 24px;
    color: black;
    padding: 0 1.25rem;
}

.action {
    margin:  5px 0;
    width: 100%;
    font-size: 0.8em;
    font-weight: bold;
}

.icons img {
    margin:0 6px ;
}


.dot {
    height: 15px;
    width: 15px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
  }


.starratingscss {
    unicode-bidi: bidi-override;
    color: #c5c5c5;
    /* font-size: 25px; */
    height: 25px;
    /* width: 100px; */
    position: relative;
    /* text-shadow: 0px 1px 0 #a2a2a2; */
  }
  .starratingscsstop {
    color: #EF4657;
    padding: 0;
    position: absolute;
    z-index: 1;
    display: flex;
    display: -webkit-flex;
    display: -webkit-inline-flex;
    top: 0;
    left: 0;
    overflow: hidden;
  }
  .starratingscssbottom {
    padding: 0;
    display: block;
    z-index: 0;
  }

  .starratingscssbottom span:last-child {
  font-size: 12px;
  padding-left: 4px;

  }