.popular {
    min-height: 400px;
}

.popular button {
    margin: 10px;
}

.popular h3 {
    line-height: 46px;
    padding-top: 40px;
    text-align: left;
}

