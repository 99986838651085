.response {
    text-align: center;
    min-height: 80vh;
}

.response b {
    margin-top: 40px;
}

.inner {
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
    padding: 40px;
    width: 50%;
    margin: 0 auto;
    box-shadow: 0 2px rgba(0,0,0,.06);
}

@media screen and (max-width: 770px) {
    .inner {
        width: 100%;
    }
}