.search {
    background-color: white;
    border-radius: 4px;
    width : 100%;
    height: 48px;
}


.searchInput {
    border: none !important;
}

.searchInput:hover, .searchInput:focus{
    outline: none;
    box-shadow:none !important;
    border:none !important;
}

.locateButton {
    height: 48px;
}


.locateI {
    display: inline-block;
    text-align: center;
    font-size: 1.5em;
    color: #9b9b9b;
    line-height: 3.2rem;
}



@media screen and (max-width: 770px) {

.button {
   text-align: center;
   margin-top: 20px;
   display: grid;
}

.search {
    margin-top: 40px;
}

.locateButton {
    border: none;
    background-color: white;
}

}




