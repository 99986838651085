.results {
    height: 73vh;
    overflow-y: scroll;
    overflow-x: hidden;
    transform: translate3d(0px, 0px, 0px);
    /* padding: 0 20px; */
}

.shops {
    padding: 10px;
    margin: 0;
    z-index: 99;
    position: relative;
    background-color: white;
}

.map {
    padding: 0;
    margin: 0;
 
}


.loadingResult {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    /* width: 47%; */
    height: 270px;
    /* float: left; */
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
    margin: 10px;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px;
}


@media (max-width: 770px) {
    .loadingResult {
        width: 100%;
    }
    .results {
        height: 100%;
        overflow-y: hidden;
        overflow-x: hidden;
        transform: translate3d(0px, 0px, 0px);
        /* padding: 0 20px; */
    }
}