.navbar {
    padding: 2px !important;
    background-color: white !important;
    /* min-height: 80px; */
    margin:20px 0;
    z-index: 1;
}

/* .navbar-nav:first-child {
 margin-left: 40px;
 box-shadow:  9px 0px 0px -8px #d9d9d9;
} */

/* 
.nav-address {
    position: fixed;
    top : 24px;
    left : 150px;
    z-index: 1;

} */

.dropdown-menu {
    left: -100px;
}



@media (max-width: 600px) { 
    .nav-address {
        position: relative;
        left: 0;
        padding-bottom: 2em;
    }

    .partnerSignup {
        display: none;
    }
}

.navbar-toggler {
    border: none !important;
}

li.nav-item.dropdown {
    list-style: none;
}
