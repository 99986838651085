.header {
    /* border-bottom: 1px solid #d9d9d9; */
    padding: 0 20px;
}



@media only screen and (max-width: 600px) {
    .header {
        height: 80px;
    }
}