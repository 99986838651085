.Backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(31, 31, 31, 0.8);
}
.Modal_Modal__2U_vV {
    position: fixed;
    z-index: 500;
    background-color: white;
    width: 90%;
    border: 1px solid #ccc;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px;
    border-radius: 4px;
    padding: 16px;
    left: 5%;
    top: 10%;
    box-sizing: border-box;
    transition: all 0.2s ease-out;
    max-height: 70vh;
    overflow: auto;
}

@media (min-width: 600px) {
    .Modal_Modal__2U_vV {
        width: 500px;
        left: calc(50% - 250px);
    }
}
.navbar-brand {
    color: #00a591 !important;
}
.MenuItems_navItem__2W0DM {
  line-height: 36px;
}
/* 
.navItem:first-child button{
  border : 1px #EF4657 solid;
  border-radius: 4px;
  color: #EF4657 !important;
  margin-right: 100px;
}

@media screen and (max-width: 992px) {
  .navItem:first-child button{
    display: none;
  }
}


.navItem:first-child a:hover{
  color: #292929 !important;
} */

.MenuItems_navLink__3B-El {
    color: #292929 !important;
    font-size: 12px; 

}


.MenuItems_navLink__3B-El:hover , .MenuItems_navLink__3B-El:focus {
    color: #EF4657 !important;
    text-decoration: none;
}


.navbar {
    padding: 2px !important;
    background-color: white !important;
    /* min-height: 80px; */
    margin:20px 0;
    z-index: 1;
}

/* .navbar-nav:first-child {
 margin-left: 40px;
 box-shadow:  9px 0px 0px -8px #d9d9d9;
} */

/* 
.nav-address {
    position: fixed;
    top : 24px;
    left : 150px;
    z-index: 1;

} */

.dropdown-menu {
    left: -100px;
}



@media (max-width: 600px) { 
    .nav-address {
        position: relative;
        left: 0;
        padding-bottom: 2em;
    }

    .partnerSignup {
        display: none;
    }
}

.navbar-toggler {
    border: none !important;
}

li.nav-item.dropdown {
    list-style: none;
}

.gears {
  width: 350px;
  margin: 100px auto;
  display: block;
  text-align: center;
  font-weight: bold;
  font-family: Arial;
  color: #ef4657;
  text-shadow: 1px 1px 2px #ee838e
}

.gears .big {
  width: 100px;
  height: 100px;
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-animation: rotate 1.5s linear infinite;
  animation: rotate 1.5s linear infinite
}

.gears .small {
  width: 70px;
  height: 70px;
  margin-left: -6px;
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-animation: reverseRotate 1.5s linear infinite;
  animation: reverseRotate 1.5s linear infinite;
}


@-webkit-keyframes rotate {
  0% {
     -webkit-transform: rotate(0);
     transform: rotate(0);
  }
  100% {
     -webkit-transform: rotate(180deg);
     transform: rotate(180deg);
  }
}


@keyframes rotate {
  0% {
     -webkit-transform: rotate(0);
     transform: rotate(0);
  }
  100% {
     -webkit-transform: rotate(180deg);
     transform: rotate(180deg);
  }
}

@-webkit-keyframes reverseRotate {
  0% {
     -webkit-transform: rotate(180deg);
     transform: rotate(180deg);
  }
  100% {
     -webkit-transform: rotate(0);
     transform: rotate(0);
  }
}

@keyframes reverseRotate {
  0% {
     -webkit-transform: rotate(180deg);
     transform: rotate(180deg);
  }
  100% {
     -webkit-transform: rotate(0);
     transform: rotate(0);
  }
}

.auth img {

    display: block;
    margin: auto;
}

.auth form {
    margin-top: 20px;
}

.auth-btn-link {
    font-size: 12px;
    line-height: 24px;
    color: #292929;
    background-color: transparent;
    padding: 0;
    margin: 0;
}

.auth-btn-link:hover {
    color: #EF4657;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent;
    cursor: pointer;
}

.auth-btn-link:focus {
    border-color: none !important;
    box-shadow:  none !important;
}

.auth .form-check .form-check-label {
    margin: 0;
    font-size: 12px;
    line-height: 24px;

    
}


/* .select2-container , .form-group label{
        margin: 20px  !important;
}
.select2-container--open .select2-dropdown--below {
    margin:-20px -10px !important;
} */

.form-group label {
    margin-top: 10px;
    display: block !important;
    width : 100%;
}


.form-group>.select2 {
    display: block;
    width: 100% !important;
}

.form-row label {
    display: block !important;
    width : 100%;
}

.UserData_check__bfa6x {
    display:-webkit-inline-flex;
    display:inline-flex;
    line-height: 28px;
    font-size: 12px;
    padding-left: 0;
}

.UserData_check__bfa6x label {
    margin-top: -8px;
    margin-left: 8px;
}


.Response_response__LjB37 {
    text-align: center;
    min-height: 80vh;
}

.Response_response__LjB37 b {
    margin-top: 40px;
}

.Response_inner__-X6k9 {
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
    padding: 40px;
    width: 50%;
    margin: 0 auto;
    box-shadow: 0 2px rgba(0,0,0,.06);
}

@media screen and (max-width: 770px) {
    .Response_inner__-X6k9 {
        width: 100%;
    }
}
.header {
    /* border-bottom: 1px solid #d9d9d9; */
    padding: 0 20px;
}



@media only screen and (max-width: 600px) {
    .header {
        height: 80px;
    }
}
.Search_search__2CkH9 {
    background-color: white;
    border-radius: 4px;
    width : 100%;
    height: 48px;
}


.Search_searchInput__22qqH {
    border: none !important;
}

.Search_searchInput__22qqH:hover, .Search_searchInput__22qqH:focus{
    outline: none;
    box-shadow:none !important;
    border:none !important;
}

.Search_locateButton__3GW3t {
    height: 48px;
}


.Search_locateI__1cEDE {
    display: inline-block;
    text-align: center;
    font-size: 1.5em;
    color: #9b9b9b;
    line-height: 3.2rem;
}



@media screen and (max-width: 770px) {

.Search_button__2qfi7 {
   text-align: center;
   margin-top: 20px;
   display: grid;
}

.Search_search__2CkH9 {
    margin-top: 40px;
}

.Search_locateButton__3GW3t {
    border: none;
    background-color: white;
}

}






.how {
    min-height: 400px;
    display: block;
    background-color : #f5f5f5;
    text-align: center;
  }
  .how h3 {
    line-height: 46px;
    padding-top: 40px;
    text-align: left;
  }
  
  
 
  .how-title {
    font-weight: 500;
  }
  
  .how .row {
    margin-top: 20px;
  }
  
  .how img {
    margin-bottom: 40px;
  }
  .how p {
    font-weight: 300;
    height: 80px;
    padding: 20px 0;
  }
  
.partner {
    background-color: white;
    background-image: url('/img/map.png');
    background-repeat: no-repeat;
    background-position: right;
    min-height: 400px;
}

.partner h3 {
    line-height: 46px;
    padding-top: 40px;
    text-align: left;
  }

  .partner p {
      color: #232323;
  }
  
@media screen and (max-width: 990px) { 
    .partner {
        background-image: none;
    }
}
 
.Popular_popular__yTnLA {
    min-height: 400px;
}

.Popular_popular__yTnLA button {
    margin: 10px;
}

.Popular_popular__yTnLA h3 {
    line-height: 46px;
    padding-top: 40px;
    text-align: left;
}


.Locality_locality__3-OuO {
    min-height: 300px;
    display: block;
    background-color : #f5f5f5;

}

.Locality_locality__3-OuO button {
    /* font-size: 1.4rem;
    line-height: 1.6rem;
    margin-right: 2rem;
    padding: .5rem 1rem; */
    border-radius: .4rem;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border: 0.1rem solid #EF4657;
    border-right: 0;
    color: #262626;
    display: inline-block;
    margin-bottom: 1rem;
    max-width: 100%;
    position: relative;
}

.Locality_tag__1ZcjO{
        display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
/* .locality button:hover , .locality button:focus{
    border-radius: .4rem;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border: 1px solid #EF4657;
    border-right: 0;
} */
.Locality_locality__3-OuO button::after {
    background: url(/img/tag-after.svg) no-repeat 100% center;
    background-size: contain;
    content: '';
    position: absolute;
    right: -1rem;
    top: -.1rem;
    bottom: -.1rem;
    width: 2.5rem;
}

.Locality_locality__3-OuO h3 {
    padding-top: 1.5em;
    margin-bottom: 1.5em
}

.Locality_locality__3-OuO img {
    border-radius: 50%;
    width: 300px;
}

  :root {
    --main-color: #ef4657  ;
  }

  main {
   /* padding-top : 20px;
   text-align: center; */
   min-height: 100vh;
  
  }
  #header {
    background:   linear-gradient(
      rgba(0, 0, 0, 0.7), 
      rgba(0, 0, 0, 0.7)
    ),url("/img/b.jpg");
    background-size: cover;
    /* background-position: top; */
    min-height: 400px;
       }

#header h3 {
    color: white;
    text-align: center;
    padding:1.6em 0;
}


.text-wrapper {
  color: white;
  text-align: center;
  font-weight: 400;
  font-size: 36px;
  margin:0px auto;
  padding:80px 0;
  line-height: 1.5rem;
  /* line-height: 43px; */
}

.text-wrapper span {
 
  font-weight: 600;
 
}


.animated-words {
  display: inline-block;
}

.animated-words span:nth-child(2) { 
  -webkit-animation-delay: 2s; 
  animation-delay: 2s; 
  color: white;
}
.animated-words span:nth-child(3) { 
  -webkit-animation-delay: 4s; 
  animation-delay: 4s; 
  color: white;
}
.animated-words span:nth-child(4) { 
  -webkit-animation-delay: 6s; 
  animation-delay: 6s; 
  color: white;
}
.animated-words span:nth-child(5) { 
  -webkit-animation-delay: 8s; 
  animation-delay: 8s; 
  color: white;
}
.animated-words span:nth-child(6) { 
  -webkit-animation-delay: 10s; 
  animation-delay: 10s; 
  color: white;
}


.animated-words span {
  position: absolute;
  left: 0;
  right: 0;
  opacity: 0;
  overflow: hidden;
  padding: 60px 0;
  color: white;
  -webkit-animation: animateWord 12s linear infinite 0s;
  animation: animateWord 12s linear infinite 0s;
}


@-webkit-keyframes animateWord {
  0% { opacity: 0; }
  2% { opacity: 0; -webkit-transform: translateY(30px); }
  5% { opacity: 1; -webkit-transform: translateY(-40px);}
  17% { opacity: 1; -webkit-transform: translateY(-40px); }
  20% { opacity: 0; -webkit-transform: translateY(-60px); }
  80% { opacity: 0; }
  100% { opacity: 0; }
}


@keyframes animateWord {
  0% { opacity: 0; }
  2% { opacity: 0; -webkit-transform: translateY(30px); }
  5% { opacity: 1; -webkit-transform: translateY(-40px);}
  17% { opacity: 1; -webkit-transform: translateY(-40px); }
  20% { opacity: 0; -webkit-transform: translateY(-60px); }
  80% { opacity: 0; }
  100% { opacity: 0; }
}

@media screen and (max-width: 770px) {

}





.results {
    height: 73vh;
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-transform: translate3d(0px, 0px, 0px);
            transform: translate3d(0px, 0px, 0px);
    /* padding: 0 20px; */
}

.shops {
    padding: 10px;
    margin: 0;
    z-index: 99;
    position: relative;
    background-color: white;
}

.map {
    padding: 0;
    margin: 0;
 
}


.loadingResult {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    /* width: 47%; */
    height: 270px;
    /* float: left; */
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
    margin: 10px;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px;
}


@media (max-width: 770px) {
    .loadingResult {
        width: 100%;
    }
    .results {
        height: 100%;
        overflow-y: hidden;
        overflow-x: hidden;
        -webkit-transform: translate3d(0px, 0px, 0px);
                transform: translate3d(0px, 0px, 0px);
        /* padding: 0 20px; */
    }
}
.result {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    /* width: 47%; */
    height: 295px;
    /* float: left; */
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
    margin: 10px;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px;
}

.result .image {
    overflow: hidden;
    background-size: contain;
    background-position: center;
    height: 50%;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
}



.result-body {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    text-align: left;
}

/* .result-body button {
    font-size: 0.9em;
    line-height: 1em
} */

.result-title {
    color: #EF4657;
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 1rem;
    padding: 0 1.25rem;
    line-height: 1.2rem;
}


.result-footer {
    padding: .75rem 1.25rem;
    background-color: rgba(0,0,0,.03);
    border-top: 1px solid rgba(0,0,0,.125);
}

.result-footer:last-child {
    border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px);
}


.result-text {
    font-size: 12px;
    line-height: 24px;
    color: black;
    padding: 0 1.25rem;
}

.action {
    margin:  5px 0;
    width: 100%;
    font-size: 0.8em;
    font-weight: bold;
}

.icons img {
    margin:0 6px ;
}


.dot {
    height: 15px;
    width: 15px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
  }


.starratingscss {
    unicode-bidi: bidi-override;
    color: #c5c5c5;
    /* font-size: 25px; */
    height: 25px;
    /* width: 100px; */
    position: relative;
    /* text-shadow: 0px 1px 0 #a2a2a2; */
  }
  .starratingscsstop {
    color: #EF4657;
    padding: 0;
    position: absolute;
    z-index: 1;
    display: flex;
    display: -webkit-flex;
    display: -webkit-inline-flex;
    top: 0;
    left: 0;
    overflow: hidden;
  }
  .starratingscssbottom {
    padding: 0;
    display: block;
    z-index: 0;
  }

  .starratingscssbottom span:last-child {
  font-size: 12px;
  padding-left: 4px;

  }
.topbar{
    /* border-bottom: 1px solid #d9d9d9; */
    background-color:rgb(240, 240, 240);
    padding: 0 20px;

}

.topbar h2 {
    padding-top: 20px;
}

.filters {
    position: relative;
    float: left;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px;
    margin : 10px;

    border-width: 1px !important;
    border-style: solid !important;
    border-color: #d9d9d9 !important;
    border-image: none !important;
    border-image: initial !important;
    border-radius: 3px !important;
    padding: 10px !important;
}

.filters:first-child {
    margin-left: 0 !important;
}


@media screen and (max-width: 770px) {
    .topbar p {
        display: none;
    }
}
.beacon{
    position:absolute;
    top:50%;
    left:50%;
    background-color:rgb(239, 70, 87);
    height:1em;
    width:1em;
    border-radius:50%;
    -webkit-transform:translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%)
  }
  .beacon:before{
    position:absolute;
    content:"";
    height:1em;
    width:1em;
    left:0;
    top:0;
    background-color:transparent;
    border-radius:50%;
    box-shadow:0px 0px 2px 2px #EF4657;
    -webkit-animation:active 2s infinite linear;
    animation:active 2s infinite linear;
  }
  
  @-webkit-keyframes active{
    0%{
      -webkit-transform:scale(.1);
      opacity:1;
    }
    70%{
      -webkit-transform:scale(2.5);
      opacity:0;
    }
    100%{
      opacity:0;
    }
  }
  
  @keyframes active{
    0%{
      -webkit-transform:scale(.1);
              transform:scale(.1);
      opacity:1;
    }
    70%{
      -webkit-transform:scale(2.5);
              transform:scale(2.5);
      opacity:0;
    }
    100%{
      opacity:0;
    }
  }
  
.Singleshop_singleShop__4bkYt .Singleshop_row__2zBcD {
    margin: 0;
}

.Singleshop_logo__14n62 {
    background-color: #fff;
    margin-top: -60px;
    border: solid 1px #d9d9d9;
    border-radius: 4px;
    box-shadow: 0 0 1px 0;
    position: relative;
    max-height: 300px;
}

.Singleshop_logo__14n62 img {
    max-height: 100%;  
    max-width: 100%; 
    width: auto;
    height: auto;
    position: absolute;  
    top: 0;  
    bottom: 0;  
    left: 0;  
    right: 0;  
    margin: auto;
}

.Singleshop_details__3ItKq, .Singleshop_info__2JNsm, .Singleshop_action__s8VUK {
    padding: 20px;
    margin-top: 20px;
}

.Singleshop_info__2JNsm h1 {
    margin-top: -10px;
    font-size: 1.5em;
}

.Singleshop_info__2JNsm span {
    /* float: left; */
    line-height: 20px;
}

.Singleshop_info__2JNsm button {
    width: 100%;
    margin: 4px;
    display: block;
    font-size: .8em;
    font-weight: bold;

}

.Singleshop_infoDetails__2WNhc {
    padding: 5px 0;
}


a:hover {
    text-decoration: none;
}

.Singleshop_details__3ItKq p, .Singleshop_info__2JNsm p, .Singleshop_action__s8VUK p {
 /* font-weight: bold; */
    font-size: .9rem;
    line-height: 1rem;
}


.Singleshop_details__3ItKq p:last-child, .Singleshop_info__2JNsm p:last-child {
    margin-top: 8px;
}

.Singleshop_details__3ItKq li {
    list-style: none;
    font-size: 0.8em;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    color: #262626;
    display: inline-block;
    line-height: 1.35;
    margin-bottom: 0.25rem;
    max-width: 100%;
    padding: .55em 1.7em;
    position: relative;
}

.Singleshop_description__207oY, .Singleshop_comments__1kGzc, .Singleshop_category__w6xyi{
    margin: 20px 0;
    border: solid 1px #d9d9d9;
    border-radius: 4px;
    padding: 20px;
}


.Singleshop_category__w6xyi li {
    list-style: none;
    display: inline;
    padding-right: 10px;
}

.Singleshop_category__w6xyi li::after {
    content: ","
}

.Singleshop_category__w6xyi li:last-child::after {
    content: "";
}


.Singleshop_action__s8VUK {
    position: absolute;
    font-size: 16px;
    background-color: #fff;
    border: solid 1px #d9d9d9;
    border-radius: 4px;
    box-shadow: 0 0 1px 0;
    left: 0;
    right: 0;
    top: 0;
}
.Singleshop_action__s8VUK button {
    width: 100%;
    margin: 4px;
    display: block;
    font-size: .8em;
    font-weight: bold;

}

.Singleshop_category__w6xyi i::before {
    font-size: 1rem;
}

.Singleshop_category__w6xyi p{
    /* float: left; */
    display: inline-block;
    padding:0 5px;
    font-size: 1rem;
    /* margin: 20px 0; */
}

.Singleshop_category__w6xyi b {
    padding-right: 20px;
}


.Singleshop_starratingscss__37IRp {
    unicode-bidi: bidi-override;
    color: #c5c5c5;
    /* font-size: 25px; */
    height: 25px;
    /* width: 100px; */
    position: relative;
    /* text-shadow: 0px 1px 0 #a2a2a2; */
  }
  .Singleshop_starratingscsstop__3BLdb {
    color: rgb(216, 0, 0);
    padding: 0;
    position: absolute;
    z-index: 1;
    display: block;
    top: 0;
    left: 0;
    overflow: hidden;
  }
  .Singleshop_starratingscssbottom__2daPK {
    padding: 0;
    display: block;
    z-index: 0;
  }

  .Singleshop_partnerComment__2MwJl h6 {
      font-weight: bold;
      margin-top: 10px;
  }



.pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #EF4657;
  position: absolute;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}
.pin:after {
  content: '';
  width: 14px;
  height: 14px;
  margin: 8px 0 0 8px;
  background: #ffffff;
  position: absolute;
  border-radius: 50%;
}


.SingleshopByName_logo__12w9y {
    background-color: #fff;
    margin-top: -60px;
    border: solid 1px #d9d9d9;
    border-radius: 4px;
    box-shadow: 0 0 1px 0;
    position: relative;
    max-height: 300px;
}

.SingleshopByName_logo__12w9y img {
    max-height: 100%;  
    max-width: 100%; 
    width: auto;
    height: auto;
    position: absolute;  
    top: 0;  
    bottom: 0;  
    left: 0;  
    right: 0;  
    margin: auto;
}

.SingleshopByName_details__1xv5b, .SingleshopByName_info__1rjG6, .SingleshopByName_action__2Zq9r {
    padding: 20px;
    margin-top: 20px;
}

.SingleshopByName_info__1rjG6 h1 {
    margin-top: -10px;
    font-size: 1.5em;
}

.SingleshopByName_info__1rjG6 span {
    /* float: left; */
    line-height: 20px;
}

.SingleshopByName_info__1rjG6 button {
    width: 100%;
    margin: 4px;
    display: block;
    font-size: .8em;
    font-weight: bold;

}

.SingleshopByName_infoDetails__nfBP2 {
    padding: 5px 0;
}


a:hover {
    text-decoration: none;
}

.SingleshopByName_details__1xv5b p, .SingleshopByName_info__1rjG6 p, .SingleshopByName_action__2Zq9r p {
 /* font-weight: bold; */
    font-size: .9rem;
    line-height: 1rem;
}


.SingleshopByName_details__1xv5b p:last-child, .SingleshopByName_info__1rjG6 p:last-child {
    margin-top: 8px;
}

.SingleshopByName_details__1xv5b li {
    list-style: none;
    font-size: 0.8em;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    color: #262626;
    display: inline-block;
    line-height: 1.35;
    margin-bottom: 0.25rem;
    max-width: 100%;
    padding: .55em 1.7em;
    position: relative;
}


.SingleshopByName_description__2_MfD, .SingleshopByName_comments__WRv5F, .SingleshopByName_category__26X0d{
    margin: 20px 0;
    border: solid 1px #d9d9d9;
    border-radius: 4px;
    padding: 20px;
}

 .SingleshopByName_discount__2ikvx{
    margin: 20px 0;
    /* border: solid 1px rgb(239, 70, 87); */
    border-radius: 4px;
    padding: 20px;
    color: rgb(239, 70, 87);
    text-align: center;
    background-color: rgb(248, 223, 226);
}

.SingleshopByName_discount__2ikvx p, .SingleshopByName_discount__2ikvx h4 {
    color: rgb(239, 70, 87);
    font-weight: bold;
}

.SingleshopByName_category__26X0d li {
    list-style: none;
    display: inline;
    padding-right: 10px;
}

.SingleshopByName_category__26X0d li::after {
    content: ","
}

.SingleshopByName_category__26X0d li:last-child::after {
    content: "";
}


.SingleshopByName_action__2Zq9r {
    position: absolute;
    font-size: 16px;
    background-color: #fff;
    border: solid 1px #d9d9d9;
    border-radius: 4px;
    box-shadow: 0 0 1px 0;
    left: 0;
    right: 0;
    top: 0;
    margin-top: -20px;
}
.SingleshopByName_action__2Zq9r button {
    width: 100%;
    margin: 4px;
    display: block;
    font-size: .8em;
    font-weight: bold;

}

.SingleshopByName_category__26X0d i::before {
    font-size: 1rem;
}

.SingleshopByName_category__26X0d p{
    /* float: left; */
    display: inline-block;
    padding:0 5px;
    font-size: 1rem;
    /* margin: 20px 0; */
}

.SingleshopByName_category__26X0d b {
    padding-right: 20px;
}


.SingleshopByName_starratingscss__WCp5R {
    unicode-bidi: bidi-override;
    color: #c5c5c5;
    /* font-size: 25px; */
    height: 25px;
    /* width: 100px; */
    position: relative;
    /* text-shadow: 0px 1px 0 #a2a2a2; */
  }
  .SingleshopByName_starratingscsstop__2y1lW {
    color: rgb(216, 0, 0);
    padding: 0;
    position: absolute;
    z-index: 1;
    display: block;
    top: 0;
    left: 0;
    overflow: hidden;
  }
  .SingleshopByName_starratingscssbottom__2jfNv {
    padding: 0;
    display: block;
    z-index: 0;
  }

  .SingleshopByName_partnerComment__2u52Q h6 {
      font-weight: bold;
      margin-top: 10px;
  }



  .SingleshopByName_showMoreButton__32Ipq {
    border: none;
    background-color: transparent;
    font-weight: 400 !important;
    font-size: 16px !important;
    text-align: left;
  }

  .SingleshopByName_showMoreButton__32Ipq:hover {
      cursor: pointer;
  }

  .SingleshopByName_showMoreButton__32Ipq:focus {
    outline: none;
    box-shadow:none !important;
    border:none !important;
  }

  .SingleshopByName_actionPhone__ue9eD { 
      display: none;
  }
  @media screen and (max-width: 990px) {

    .SingleshopByName_action__2Zq9r {
        display: block ;
        position: relative;
    }
}

@media  screen and (max-width: 600px) {
    .SingleshopByName_action__2Zq9r {
        display: none;
    }
    .SingleshopByName_actionPhone__ue9eD {
        display: block;
        position: fixed;
        font-size: 16px;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 99;
        padding: 10px;
    }

    .SingleshopByName_singleShopMap__184F3 {
        display: block;
        z-index: -1;
    }
} 
.Footer_footer__p27Jo {

        background: #292929;
        color: #fff;
        padding: 0 20px;
        min-height: 100px;

    }

   .Footer_footer__p27Jo  .Footer_row__1rUav {
      padding: 0 20px;
   }

 .Footer_footerLogo__2Uhce {

    display: -webkit-flex;

    display: flex;
    color: #fff;
    height: 66px;
    font-size: 24px;
    padding:  20px 0;

 }

 .Footer_footer__p27Jo  h5 {
    font-size: 0.85rem;
    color: #b3b3b3;
    margin-top: 10px;
    text-transform: uppercase;
 }

.Footer_footer__p27Jo a, .Footer_footer__p27Jo p {
   color: white;
   font-size: 0.85rem;
}

.Footer_footer__p27Jo  a:hover {
   color: #b3b3b3;
}


.Footer_footer__p27Jo  li {
   list-style: none;
   margin: 5px 0;
}

 .Footer_socialIcons__2qy8E a{
    color: #fff;
    margin:4px;
    height: 40px;
   font-size: 2rem; }

 
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

