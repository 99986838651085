.partner {
    background-color: white;
    background-image: url('/img/map.png');
    background-repeat: no-repeat;
    background-position: right;
    min-height: 400px;
}

.partner h3 {
    line-height: 46px;
    padding-top: 40px;
    text-align: left;
  }

  .partner p {
      color: #232323;
  }
  
@media screen and (max-width: 990px) { 
    .partner {
        background-image: none;
    }
}
 