.check {
    display:inline-flex;
    line-height: 28px;
    font-size: 12px;
    padding-left: 0;
}

.check label {
    margin-top: -8px;
    margin-left: 8px;
}

