.navItem {
  line-height: 36px;
}
/* 
.navItem:first-child button{
  border : 1px #EF4657 solid;
  border-radius: 4px;
  color: #EF4657 !important;
  margin-right: 100px;
}

@media screen and (max-width: 992px) {
  .navItem:first-child button{
    display: none;
  }
}


.navItem:first-child a:hover{
  color: #292929 !important;
} */

.navLink {
    color: #292929 !important;
    font-size: 12px; 

}


.navLink:hover , .navLink:focus {
    color: #EF4657 !important;
    text-decoration: none;
}

