.locality {
    min-height: 300px;
    display: block;
    background-color : #f5f5f5;

}

.locality button {
    /* font-size: 1.4rem;
    line-height: 1.6rem;
    margin-right: 2rem;
    padding: .5rem 1rem; */
    border-radius: .4rem;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border: 0.1rem solid #EF4657;
    border-right: 0;
    color: #262626;
    display: inline-block;
    margin-bottom: 1rem;
    max-width: 100%;
    position: relative;
}

.tag{
        display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
/* .locality button:hover , .locality button:focus{
    border-radius: .4rem;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border: 1px solid #EF4657;
    border-right: 0;
} */
.locality button::after {
    background: url(/img/tag-after.svg) no-repeat 100% center;
    background-size: contain;
    content: '';
    position: absolute;
    right: -1rem;
    top: -.1rem;
    bottom: -.1rem;
    width: 2.5rem;
}

.locality h3 {
    padding-top: 1.5em;
    margin-bottom: 1.5em
}

.locality img {
    border-radius: 50%;
    width: 300px;
}