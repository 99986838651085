.singleShop .row {
    margin: 0;
}

.logo {
    background-color: #fff;
    margin-top: -60px;
    border: solid 1px #d9d9d9;
    border-radius: 4px;
    box-shadow: 0 0 1px 0;
    position: relative;
    max-height: 300px;
}

.logo img {
    max-height: 100%;  
    max-width: 100%; 
    width: auto;
    height: auto;
    position: absolute;  
    top: 0;  
    bottom: 0;  
    left: 0;  
    right: 0;  
    margin: auto;
}

.details, .info, .action {
    padding: 20px;
    margin-top: 20px;
}

.info h1 {
    margin-top: -10px;
    font-size: 1.5em;
}

.info span {
    /* float: left; */
    line-height: 20px;
}

.info button {
    width: 100%;
    margin: 4px;
    display: block;
    font-size: .8em;
    font-weight: bold;

}

.infoDetails {
    padding: 5px 0;
}


a:hover {
    text-decoration: none;
}

.details p, .info p, .action p {
 /* font-weight: bold; */
    font-size: .9rem;
    line-height: 1rem;
}


.details p:last-child, .info p:last-child {
    margin-top: 8px;
}

.details li {
    list-style: none;
    font-size: 0.8em;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    color: #262626;
    display: inline-block;
    line-height: 1.35;
    margin-bottom: 0.25rem;
    max-width: 100%;
    padding: .55em 1.7em;
    position: relative;
}

.description, .comments, .category{
    margin: 20px 0;
    border: solid 1px #d9d9d9;
    border-radius: 4px;
    padding: 20px;
}


.category li {
    list-style: none;
    display: inline;
    padding-right: 10px;
}

.category li::after {
    content: ","
}

.category li:last-child::after {
    content: "";
}


.action {
    position: absolute;
    font-size: 16px;
    background-color: #fff;
    border: solid 1px #d9d9d9;
    border-radius: 4px;
    box-shadow: 0 0 1px 0;
    left: 0;
    right: 0;
    top: 0;
}
.action button {
    width: 100%;
    margin: 4px;
    display: block;
    font-size: .8em;
    font-weight: bold;

}

.category i::before {
    font-size: 1rem;
}

.category p{
    /* float: left; */
    display: inline-block;
    padding:0 5px;
    font-size: 1rem;
    /* margin: 20px 0; */
}

.category b {
    padding-right: 20px;
}


.starratingscss {
    unicode-bidi: bidi-override;
    color: #c5c5c5;
    /* font-size: 25px; */
    height: 25px;
    /* width: 100px; */
    position: relative;
    /* text-shadow: 0px 1px 0 #a2a2a2; */
  }
  .starratingscsstop {
    color: rgb(216, 0, 0);
    padding: 0;
    position: absolute;
    z-index: 1;
    display: block;
    top: 0;
    left: 0;
    overflow: hidden;
  }
  .starratingscssbottom {
    padding: 0;
    display: block;
    z-index: 0;
  }

  .partnerComment h6 {
      font-weight: bold;
      margin-top: 10px;
  }


