
  :root {
    --main-color: #ef4657  ;
  }

  main {
   /* padding-top : 20px;
   text-align: center; */
   min-height: 100vh;
  
  }
  #header {
    background:   linear-gradient(
      rgba(0, 0, 0, 0.7), 
      rgba(0, 0, 0, 0.7)
    ),url("/img/b.jpg");
    background-size: cover;
    /* background-position: top; */
    min-height: 400px;
       }

#header h3 {
    color: white;
    text-align: center;
    padding:1.6em 0;
}


.text-wrapper {
  color: white;
  text-align: center;
  font-weight: 400;
  font-size: 36px;
  margin:0px auto;
  padding:80px 0;
  line-height: 1.5rem;
  /* line-height: 43px; */
}

.text-wrapper span {
 
  font-weight: 600;
 
}


.animated-words {
  display: inline-block;
}

.animated-words span:nth-child(2) { 
  -webkit-animation-delay: 2s; 
  -ms-animation-delay: 2s; 
  animation-delay: 2s; 
  color: white;
}
.animated-words span:nth-child(3) { 
  -webkit-animation-delay: 4s; 
  -ms-animation-delay: 4s; 
  animation-delay: 4s; 
  color: white;
}
.animated-words span:nth-child(4) { 
  -webkit-animation-delay: 6s; 
  -ms-animation-delay: 6s; 
  animation-delay: 6s; 
  color: white;
}
.animated-words span:nth-child(5) { 
  -webkit-animation-delay: 8s; 
  -ms-animation-delay: 8s; 
  animation-delay: 8s; 
  color: white;
}
.animated-words span:nth-child(6) { 
  -webkit-animation-delay: 10s; 
  -ms-animation-delay: 10s; 
  animation-delay: 10s; 
  color: white;
}


.animated-words span {
  position: absolute;
  left: 0;
  right: 0;
  opacity: 0;
  overflow: hidden;
  padding: 60px 0;
  color: white;
  -webkit-animation: animateWord 12s linear infinite 0s;
  -ms-animation: animateWord 12s linear infinite 0s;
  animation: animateWord 12s linear infinite 0s;
}


@keyframes animateWord {
  0% { opacity: 0; }
  2% { opacity: 0; -webkit-transform: translateY(30px); }
  5% { opacity: 1; -webkit-transform: translateY(-40px);}
  17% { opacity: 1; -webkit-transform: translateY(-40px); }
  20% { opacity: 0; -webkit-transform: translateY(-60px); }
  80% { opacity: 0; }
  100% { opacity: 0; }
}

@media screen and (max-width: 770px) {

}




