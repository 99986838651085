.topbar{
    /* border-bottom: 1px solid #d9d9d9; */
    background-color:rgb(240, 240, 240);
    padding: 0 20px;

}

.topbar h2 {
    padding-top: 20px;
}

.filters {
    position: relative;
    float: left;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px;
    margin : 10px;

    border-width: 1px !important;
    border-style: solid !important;
    border-color: #d9d9d9 !important;
    border-image: initial !important;
    border-radius: 3px !important;
    padding: 10px !important;
}

.filters:first-child {
    margin-left: 0 !important;
}


@media screen and (max-width: 770px) {
    .topbar p {
        display: none;
    }
}